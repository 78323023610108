import React from "react";
import { Fragment } from "react";
import { useState } from "react";
function Load(props) {
  const [dloading, setDloading] = useState(true);
  const [count, setCount] = useState(0);
  const [permission_status, setPermission_status] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isQuit, setIsQuit] = useState(true);

  function agree() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
        props.permissionChange(true).then((res) => {
          setDloading(false);
        });
      })
      .catch((err) => {
        setPermission_status(true);
      });
  }
  //用户重新授权
  function agreeAuthorization() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
    agree();
  }
  function decodeUrl(encodedUrl) {
    return decodeURIComponent(encodedUrl.replace(/\+/g, " "));
  }
  function getQuery(key) {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
  }
  //用户取消授权
  function cancelAuthorization() {
    var redirect = getQuery("redirect") || "/";
    // 判断redirect是否有 ? 号
    if (redirect.indexOf("?") > -1) {
      window.location.href = decodeUrl(redirect) + "&is_cancel=1";
    } else {
      window.location.href = decodeUrl(redirect) + "?is_cancel=1";
    }
  }

  return (
    <Fragment>
      {dloading && (
        <div className='fixed overflow-hidden  z-10 w-full h-screen flex justify-center  items-center '>
          <div className='bg-black opacity-60 absolute  z-10 top-0 w-full h-screen'> </div>
          <div className='relative shadow-md z-20 w-1/2'>
            <div className=' border-blue-100 rounded-md bg-white border-t-8 border-t-df-300 p-4' role='alert'>
              {permission_status ? (
                <div>
                  <div className='flex items-center gap-4'>
                    <span className='shrink-0 rounded-full bg-df-600 p-2 text-white'>
                      <svg className='h-4 w-4' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z' />
                      </svg>
                    </span>
                    <span className='font-medium text-lg ml-2'>Lỗi ủy quyền!</span>
                  </div>
                  <div className='mt-4 text-gray-700'>Lỗi ủy quyền</div>
                  <div className='flex space-x-2'>
                    <button
                      onClick={agreeAuthorization}
                      disabled={loading}
                      className='mt-4 bg-df-0  w-full rounded-lg flex items-center justify-center    px-5 py-3 text-center text-sm font-semibold text-white cursor-pointer'>
                      {loading && (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='1.5'
                          stroke='currentColor'
                          className='animate-spin w-6 h-6'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495'
                          />
                        </svg>
                      )}
                      Ủy quyền lại
                    </button>
                    {isQuit && (
                      <div
                        onClick={cancelAuthorization}
                        className='mt-4 bg-gray-400 inline-block w-full rounded-lg   px-5 py-3 text-center text-sm font-semibold text-white cursor-pointer'>
                        Hủy bỏ
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className='flex items-center gap-4'>
                    <span className='shrink-0 rounded-full bg-df-600 p-2 text-white'>
                      <svg className='h-4 w-4' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z' />
                      </svg>
                    </span>
                    <span className='font-medium text-lg ml-2'>Lời nhắc nhở</span>
                  </div>
                  <div className='mt-4 text-gray-700'>Tôi cần yêu cầu quyền truy cập máy ảnh của bạn để chụp ảnh khuôn mặt</div>
                  <div className='mt-6 '>
                    <span
                      onClick={agree}
                      style={{ backgroundColor: window.themeColor }}
                      className='mt-4 inline-block w-full rounded-lg   px-5 py-3 text-center text-sm font-semibold text-white cursor-pointer'>
                      Được rồi
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
export default Load;
// Compare this snippet from my-app\src\index.js:
