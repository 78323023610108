import { React, Fragment, useState } from "react";
import MyComponent from "./component/load";
import FacialPhotography from "./component/FacialPhotography";
import z from "./asstes/vi-z.png";
import f from "./asstes/vi-f.png";
import r from "./asstes/vi-r.png";
import "./App.css";
function getQuery(key) {
  var urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}
function App() {
  const [showFacia, setShowFacia] = useState(false);
  const [dloading, setDloading] = useState(true);
  if (!(getQuery("token") && getQuery("redirect"))) {
    return <div>ERROR</div>;
  }
  const permissionChange = function (params) {
    return new Promise((resolve, reject) => {
      if (params) {
        setShowFacia(true);
        setDloading(false);
      }
    });
  };
  return (
    <Fragment>
      {dloading && <MyComponent permissionChange={permissionChange}></MyComponent>}
      {showFacia && <FacialPhotography></FacialPhotography>}
      <div>
        <div className='w-full h-10' style={{ backgroundColor: "#209F72" }}></div>
      </div>
      <div className='w-4/5 my-4 m-auto flex justify-around items-center'>
        <div style={{ backgroundColor: "#209F72" }} className='w-4 h-4 p-3 rounded-full bg-slate-200 text-white flex justify-center items-center'>1</div>
        <div className='bg-slate-200 w-full mx-4' style={{ height: "1px" }}></div>
        <div style={{ backgroundColor: "#209F72" }} className='w-4 h-4 p-3 rounded-full bg-slate-200 text-white flex justify-center items-center'>2</div>
        <div className='bg-slate-200 w-full mx-4' style={{ height: "1px" }}></div>
        <div style={{ backgroundColor: "#209F72" }} className='w-4 h-4 p-3 rounded-full bg-slate-400 text-white flex justify-center items-center'>
          3
        </div>
        <div className='bg-slate-200 w-full mx-4' style={{ height: "1px" }}></div>
        <div className='w-4 h-4 p-3 rounded-full bg-slate-200 text-white flex justify-center items-center'>4</div>
      </div>
      <div className='flex flex-col items-center justify-center min-h-screen py-2'>
        <img src={z} alt='' />
        <img src={f} alt='' />
        <img src={r} alt='' />
      </div>
    </Fragment>
    // <div className='App'>
    //   <header className='App-header'>
    //     <img src={logo} className='App-logo' alt='logo' />
    //     <p>
    //       <b className='text-red-400'> Edit</b> <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a className='App-link' href='https://reactjs.org' target='_blank' rel='noopener noreferrer'>
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
