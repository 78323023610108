/* global receivedMessage */

import React from "react";
import { useState, useEffect } from "react";
import "./FacialPhotography.css";
// import RecordRTC from "recordrtc";
import axios from "axios";
import * as faceapi from "face-api.js";
import UpfileLoading from "./UpfileLoading";
function FacialPhotography() {
  const [video, setVideo] = useState(null);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  useEffect(() => {
    startTimer();
    startRecording();
    Promise.all([faceapi.nets.tinyFaceDetector.loadFromUri("/data/")]);
  }, []);
  function getQuery(key) {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
  }

  const [dloading] = useState(true);
  const [imgUrl, setImgUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [faceScore, setFaceScore] = useState(0);
  // var mediaRecorder = null;
  var [timeoutFnc, setTimeoutFnc] = useState(null);
  const startTimer = () => {
    clearTimeout(timeoutFnc);
    setTimeoutFnc(
      setTimeout(() => {
        alert("Hết hạn ủy quyền");
        // 刷新当前页面
        window.location.reload();
      }, 1000 * 220)
    );
  };
  function decodeUrl(encodedUrl) {
    return decodeURIComponent(encodedUrl.replace(/\+/g, " "));
  }
  function startRecording() {
    navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      console.log("授权");
      const videoElement = document.createElement("video");
      videoElement.autoplay = true;
      videoElement.muted = true;
      videoElement.playsinline = true;
      videoElement.style.width = "100%";
      videoElement.setAttribute("playsinline", true); // 添加playsinline属性
      videoElement.style.transform = "scaleX(-1)";
      videoElement.style.controls = false;
      // videoElement.style.opacity = 0.001;
      videoElement.style.zIndex = "999";
      videoElement.srcObject = stream;
      //删除 document.getElementById("videoElementBox")的所有子节点
      document.getElementById("videoBox").innerHTML = "";
      document.getElementById("videoBox").appendChild(videoElement);
      setVideo(videoElement);
      videoElement.addEventListener("loadeddata", () => {
        // prepare = true;
        video_addEventListener(stream);

        setLoading(false);
      });
    });
  }
  function video_addEventListener(stream) {
    // mediaRecorder = new RecordRTC(stream, {
    //   type: "video",
    // });
  }

  function submit() {
    setFileUploadStatus(true);
    const blob = dataURItoBlob(imgUrl);
    const file = new File([blob], "screenshot.png", { type: "image/png" });
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("faceScore", faceScore);
    var token = getQuery("token") || "123";
    var redirect = getQuery("redirect") || "/";

    setLoading(false);
    axios
      .post("https://newwinhome.fuvay.cc/api/user/face_pull", formData, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: token,
          "x-api-token": token,
          "x-api-st": token,
          "Client-Source": "Winhome-face",
          "lang-code": 400,
        },
      })
      .then((res) => {
        clearTimeout(timeoutFnc);
        console.log(res.data.code);
        if (res.data.code == 20000) {
          setFileUploadStatus(false);
          alert("Xác minh ảnh đã hoàn thành");
          window.location.href = decodeUrl(redirect);
        } else {
          //关闭当前页面
          alert("Không đạt, vui lòng thử lại");
          // 刷新当前页面
          window.location.reload();
        }
      })
      .catch((err) => {
        //关闭当前页面
        alert(" Vui lòng liên hệ với nhân viên dịch vụ khách hàng để báo cáo vấn đề xảy ra trên máy chủ xác thực");
        // 刷新当前页面
        window.location.reload();
      });
  }

  async function take() {
    if (loading) return;
    const canvas = document.createElement("canvas");
    const videoWidth = video.videoWidth || 400;
    const videoHeight = video.videoHeight || 400;
    video.style.opacity = 0.001;
    video.style.position = "";
    video.style.zIndex = "-1";
    // 设置 canvas 的宽高
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    // 将 video 的画面绘制到 canvas 上
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // 清除canvas内容
    ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
    // 将 canvas 的内容转为图片并保存
    const imgData = canvas.toDataURL("image/png");
    setImgUrl(imgData);

    var img = document.getElementById("img");
    img.style.opacity = 1;
    img.style.zIndex = "999";
    img.style.width = videoWidth;
    img.style.height = videoHeight;

    const detectionOptions = new faceapi.TinyFaceDetectorOptions({
      inputSize: 224,
      scoreThreshold: 0.4,
    });
    setFileUploadStatus(true);
    const detection = await faceapi.detectSingleFace(canvas, detectionOptions);
    if (detection) {
      // 获取人脸的匹配度
      const faceScore = detection.score;
      setFaceScore(faceScore);
    }
    setFileUploadStatus(false);
  }
  function rephotograph() {
    var img = document.getElementById("img");
    img.style.opacity = 0.01;
    img.style.zIndex = "-1";
    video.style.opacity = 1;
    video.style.position = "relative";
    video.style.zIndex = "9999";
    setImgUrl(null);
    // 清除画布
  }
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  return (
    <div>
      {fileUploadStatus && <UpfileLoading> </UpfileLoading>}
      {dloading && (
        <div className='fixed overflow-hidden  z-10 w-full h-screen flex justify-center  items-center '>
          <div className='bg-black opacity-70 absolute  z-10 top-0 w-full h-screen'> </div>
          <div className='relative shadow-md z-20'>
            <div className=' text-white border-blue-100 rounded-md bg-white border-t-8 border-t-df-300 ' role='alert'>
              {loading && (
                <section className='dots-container'>
                  <div className='dot'></div>
                  <div className='dot'></div>
                  <div className='dot'></div>
                  <div className='dot'></div>
                  <div className='dot'></div>
                </section>
              )}
              <div className='box'>
                <img src={imgUrl} id='img' style={{ transform: "scaleX(-1)" }} />
                <div className='shade'></div>
                <div id='videoBox'></div>
                <div className='btngroup flex justify-between px-2 mt-6 '>
                  {!imgUrl && (
                    <div onClick={take} className='bg-blue-500 w-full rounded-full flex items-center justify-center shadow-xl'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'>
                        <path d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z' />
                        <path d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z' />
                      </svg>
                      Chụp ảnh
                    </div>
                  )}

                  {imgUrl && (
                    <div onClick={rephotograph} className='w-full rounded-full flex items-center justify-center bg-yellow-300 shadow-xl'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3'
                        />
                      </svg>
                      Chụp lại
                    </div>
                  )}
                  {imgUrl && (
                    <div onClick={submit} className='w-full ml-4 rounded-full flex items-center justify-center bg-blue-500 shadow-xl'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'>
                        <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5' />
                      </svg>
                      Hoàn thành
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FacialPhotography;
