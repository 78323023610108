import React from "react";
import "./UpfileLoading.css";
function UpfileLoading() {
  return (
    <div className='body'>
      <div className='dot-spinner'>
        <div className='dot-spinner__dot'></div>
        <div className='dot-spinner__dot'></div>
        <div className='dot-spinner__dot'></div>
        <div className='dot-spinner__dot'></div>
        <div className='dot-spinner__dot'></div>
        <div className='dot-spinner__dot'></div>
        <div className='dot-spinner__dot'></div>
        <div className='dot-spinner__dot'></div>
      </div>
      <div style={{ textAlign: "center", fontSize: "18px", color: "rgb(25, 147, 255)", position: "absolute", width: "100%", top: "55%" }}>
        Đang xác thực, vui lòng đợi trong khoảng một phút
      </div>
    </div>
  );
}

export default UpfileLoading;
